/* eslint-disable camelcase */
import { NotionRenderer } from 'react-notion-x'
import 'react-notion-x/src/styles.css'
import { LayoutContainer } from '../../Components/Layout'
import devicesJson from './devices.notion.json'

function PixelHeartDevices() {
  return (
    <LayoutContainer title='Device Setup' hidePrefix>
      <NotionRenderer
        darkMode={true}
        recordMap={{
          block: devicesJson as any,
          collection: {},
          collection_view: {},
          notion_user: {},
          collection_query: {},
          signed_urls: {},
        }}
      />
    </LayoutContainer>
  )
}

export default PixelHeartDevices
