import { Box, Card, Grid, Link, Typography } from '@mui/joy'
import { SxProps } from '@mui/material'
import { AppLogo } from 'Components/AppLogo'
import { ReactNode, memo, useEffect } from 'react'

type NotionPageProps = {
  title: string
  children?: ReactNode | undefined
  hidePrefix?: boolean
}

export const LayoutContainer = memo(({ title, children }: NotionPageProps) => {
  useEffect(() => {
    document.title = `Pixel Heart | ${title || process.env.REACT_APP_SITE_DESCRIPTION}`
  }, [])
  return (
    <Grid container direction='column' justifyContent='center' alignItems='center' display='flex'>
      <Grid
        container
        direction='column'
        justifyContent='center'
        alignItems='center'
        display='flex'
        sx={{ width: { xs: '100%', sm: '100%', md: 720, lg: 720, borderRadius: 10 } }}
      >
        <Grid sx={{ mt: 5, pl: 2, width: '100%' }} display='flex' direction='row'>
          <AppLogo url='/pixelheart_icon.png' name={title} size={50} vertical={false} fontSizeRem={1.575} href='/' />
          <Box></Box>
        </Grid>
        <Box sx={{ marginTop: 2 }}></Box>
        <Card
          sx={{
            p: 0,
            py: 2,
            mt: 2,
            border: 0,
            width: { borderRadius: 10 },
          }}
        >
          {children}
        </Card>
        <Box sx={{ mt: 4, mb: 4 }}>{/* <BottomNav /> */}</Box>
      </Grid>
    </Grid>
  )
})

export const BottomNav = memo(() => {
  const linkSx: SxProps = { color: '#FFFFFF99' }
  return (
    <Typography sx={{ color: '#FFFFFF33' }}>
      <Link href={`mailto:${process.env.REACT_APP_SUPPORT_EMAIL}`} fontWeight={400} fontSize={14} sx={linkSx}>
        Contact
      </Link>
    </Typography>
  )
})
