import { extendTheme } from '@mui/joy'

// Default theme
export const DefaultTheme = extendTheme({
  fontFamily: {
    display: 'Inter',
    body: 'Inter',
  },
  colorSchemes: {
    dark: {
      palette: {
        background: {
          body: '#000000',
        },
      },
    },
  },
  // palette: {
  //   mode: 'dark',
  //   primary: {
  //     main: '#673fd7',
  //   },
  //   secondary: {
  //     main: '#a9a9a9',
  //   },
  //   background: {
  //     paper: '#1d1c20',
  //   },
  //   text: {
  //     secondary: '#a9a9a9',
  //   },
  // },
  components: {
    JoyCard: {
      defaultProps: {
        variant: 'outlined',
      },
      styleOverrides: {
        root: ({ ownerState }) => ({
          borderRadius: '6px',
          border: '1px solid rgba(255,255,255,.08)',
          ...(ownerState.variant === 'outlined' && {
            background: 'rgba(29, 28, 32, 0.6)',
          }),
        }),
      },
    },
    JoyDivider: {
      defaultProps: {
        sx: {
          mt: 2,
          mb: 2,
        },
      },
    },
    JoyButton: {
      styleOverrides: {
        root: ({ ownerState, theme }) => ({
          // borderRadius: '9999px',
          background: 'rgba(29, 28, 32, 0.6)',
          ...(ownerState.color === 'primary' && {
            background: 'linear-gradient(92.88deg, #455eb5 9.16%, #5643cc 43.89%, #673fd7 64.72%)',
            textShadow: 'rgba(0, 0, 0, 0.25) 0px 3px 8px',
            '&:hover': {
              background: '#673fd7',
            },
          }),
        }),
      },
    },
    // JoyButton: {
    //   styleOverrides: {
    //     root: ({ ownerState, theme }) => ({
    //       // borderRadius: '9999px',
    //       ...(ownerState.color === 'info' && {
    //         background: 'linear-gradient(92.88deg, #455eb5 9.16%, #5643cc 43.89%, #673fd7 64.72%)',
    //         textShadow: 'rgba(0, 0, 0, 0.25) 0px 3px 8px',
    //       }),
    //       '&:hover': {
    //         background: '#673fd7',
    //       },
    //     }),
    //   },
    // },
    JoySheet: {
      defaultProps: {
        variant: 'outlined',
        sx: {
          p: 2,
          gap: 1,
          display: 'flex',
          flexDirection: 'column',
        },
      },
      styleOverrides: {
        root: ({ ownerState }) => ({
          borderRadius: '6px',
          border: '1px solid rgba(255,255,255,.08)',
          ...(ownerState.variant === 'outlined' && {
            background: 'rgba(29, 28, 32, 0.6)',
          }),
        }),
      },
    },
    JoyLink: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          textDecoration: 'none',
          '&:hover': {
            color: '#673fd7',
            textDecoration: 'none',
          },
        }),
      },
    },
    JoyList: {
      defaultProps: {
        sx: {
          pt: 0,
          pl: 2,
        },
      },
    },
    JoyListItem: {
      defaultProps: {
        sx: {
          ml: 1,
          pl: 0,
        },
      },
      styleOverrides: {
        root: ({ ownerState }) => ({
          display: 'list-item',
          minBlockSize: '0',
        }),
      },
    },
  },
})
