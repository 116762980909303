import { Box, Link, Stack, Typography, TypographySystem } from '@mui/joy'
import { SxProps } from '@mui/material'
import { memo } from 'react'

type AppTitleProps = {
  name: string
  suffix?: string
  page?: string
  level?: keyof TypographySystem | 'inherit'
  fontSizeRem?: number
  fontWeight?: number
  sx?: SxProps
}

export const AppTitle = memo(
  ({ name, suffix, page, fontSizeRem, fontWeight = 600, sx, level = 'h2' }: AppTitleProps) => {
    return (
      <Stack flexDirection='row' justifyContent='center' alignSelf='center' sx={sx}>
        <Typography level={level} fontSize={`${fontSizeRem}rem`} fontWeight={fontWeight}>
          {name}
        </Typography>
        {suffix && (
          <Typography
            level={level}
            fontSize={`${fontSizeRem}rem`}
            fontWeight={fontWeight}
            sx={{ color: '#FF4081', lineHeight: 1.2 }}
          >
            {suffix}
          </Typography>
        )}
        {page && (
          <Typography level={level} fontSize={`${fontSizeRem}rem`} fontWeight={fontWeight} sx={{ ml: 1 }}>
            {page}
          </Typography>
        )}
      </Stack>
    )
  },
)

type AppIconProps = {
  url: string
  size: number
  sx?: SxProps
}

export const AppIcon = memo(({ url, size, sx }: AppIconProps) => {
  return (
    <Box
      sx={{
        width: size,
        height: size,
        ...sx,
      }}
    >
      <Box
        sx={{
          width: size,
          height: size,
          position: 'relative',
        }}
      >
        <Box
          sx={{
            position: 'absolute',
            width: '100%',
            height: '100%',
            zIndex: -1,
            filter: `blur(${size / 4}px)`,
            background: 'linear-gradient(92.88deg, rgb(86, 67, 204) 43.89%, rgb(103, 63, 215) 64.72%)',
          }}
        />
        <Box
          sx={{
            position: 'absolute',
            width: '100%',
            height: '100%',
            background: `url(${url})`,
            backgroundSize: 'cover',
            borderRadius: `${size / 10}px`,
          }}
        ></Box>
      </Box>
    </Box>
  )
})

type AppLogoProps = {
  url: string
  name: string
  suffix?: string
  page?: string
  size?: number
  vertical?: boolean
  sx?: SxProps
  href?: string
  fontSizeRem?: number
}

const kDefaultIconSize = 80
const kDefaultFontSizeRem = 1.875

export const AppLogo = memo(
  ({ name, suffix, page, url, vertical = true, size = kDefaultIconSize, sx, href, fontSizeRem }: AppLogoProps) => {
    return (
      <Link
        href={href}
        sx={{
          textDecoration: 'none',
          cursor: href ? 'pointer' : 'default',
          '&:hover': { textDecoration: 'none' },
          '&:focus': { textDecoration: 'none' },
          '&:visited': { textDecoration: 'none' },
          '&:active': { textDecoration: 'none' },
        }}
      >
        <Stack
          direction={vertical ? 'column' : 'row'}
          sx={{
            ...sx,
          }}
          alignItems='center'
          alignContent='center'
          justifyContent={'center'}
        >
          <AppIcon
            size={size}
            url={url}
            sx={href ? { transition: 'all .2s ease-in-out', '&:hover': { transform: 'scale(1.1)' } } : {}}
          />
          <AppTitle
            name={name}
            suffix={suffix}
            page={page}
            sx={{ ml: vertical ? 0 : 2, mt: vertical ? 1 : 0 }}
            fontSizeRem={fontSizeRem || (size / kDefaultIconSize) * kDefaultFontSizeRem}
          />
        </Stack>
      </Link>
    )
  },
)
