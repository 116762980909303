import { Grid } from '@mui/joy'
import { ReactNode } from 'react'

type Props = {
  children?: ReactNode | undefined
}

export const HeroContainer = ({ children }: Props) => {
  return (
    <Grid container direction='column' justifyContent='center' alignItems='center' display='flex' minHeight='100vh'>
      {children}
    </Grid>
  )
}
