import { Typography } from '@mui/joy'
import { memo } from 'react'

export const HeroFooter = memo(() => {
  return (
    <Typography fontWeight={400} fontSize={12} sx={{ color: '#FFFFFF33' }}>
      MADE WITH ♥ IN VENICE, CA • PIXEL HEART LLC • 2024
    </Typography>
  )
})
